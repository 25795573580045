import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Box,
} from "@material-ui/core";
import { Link } from "gatsby";
import { v4 } from "uuid";
import SEO from "../seo";
import { grey } from "@material-ui/core/colors";

const CreateAccount = (props) => {
  const [state, setState] = useState({ page: 0 });

  const [data, setData] = useState({
    name: "",
    email: "",
    company: "",
    location: "",
    phone: "",
    note: "",
    teamSize: "",
    companyType: "",
    userRole: "",
    recordId: "",
    teamUsers: [
      { id: v4(), email: "" },
      { id: v4(), email: "" },
      { id: v4(), email: "" },
    ],
    isManager: "",
  });

  useEffect(() => {
    setState({ page: 1 });
    setData((s) => ({
      ...s,
      ...(props.location.state?.data || []),
      recordId: props.location.state?.data?.recordId || v4(),
    }));
  }, []);

  const handleFormFieldChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleInvitesChanged = (e) => {
    const { name, value } = e.target;
    setData((s) => {
      const updated = [...s.teamUsers];
      const idx = updated.findIndex((t) => t.id === name);
      if (idx !== -1) {
        const user = updated.splice(idx, 1)[0];
        user.email = value;
        updated.splice(idx, 0, user);
      }
      return { ...s, teamUsers: updated };
    });
  };

  const handleClick = React.useCallback(() => {
    setState((s) => ({ page: s.page + 1 }));
    axios.put(
      `https://lablog-62234.firebaseio.com/request-trial/${data.recordId}.json`,
      { data }
    );
  }, [data]);

  if (state.page === 0) return <></>;

  return (
    <Box bgcolor={grey[100]} pt={6} pb={6} height="100%">
      <SEO title="Create account" />

      <Box display="flex" justifyContent="center" component="h1">
        Create a free account
      </Box>

      <Container maxWidth="sm">
        <Paper
          style={{
            fontWeight: "400",
            padding: "8px 20px",
            marginBottom: "15px",
            backgroundColor: "#FFFFFF",
          }}
        >
          {state.page === 1 && (
            <>
              <h1 style={{ fontFamily: "Roboto", fontWeight: "200" }}>
                What's your name?
              </h1>
              <p style={{ color: "#757575" }}>
                Your name will be displayed on your lab notes and anything you
                share with your team.
              </p>
              <Box mb={1}>
                <TextField
                  label="Your name"
                  name="name"
                  size="small"
                  variant="outlined"
                  value={data.name}
                  onChange={handleFormFieldChange}
                />
              </Box>
              {!props.location.state?.data?.email && (
                <Box mt={1} mb={1}>
                  <TextField
                    label="Email"
                    size="small"
                    name="email"
                    variant="outlined"
                    value={data.email}
                    onChange={handleFormFieldChange}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                disabled={!data.name || !data.email}
                onClick={handleClick}
              >
                Continue to Team Info&nbsp;&nbsp;&nbsp;&nbsp;⟶
              </Button>
            </>
          )}
          {state.page === 2 && (
            <>
              <h1 style={{ fontFamily: "Roboto", fontWeight: "200" }}>
                Tell us about your team
              </h1>
              <br />
              <p style={{ color: "#757575" }}>How big is your company?</p>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="teamsize-select">Team size</InputLabel>
                <Select
                  value={data.teamSize}
                  name="teamSize"
                  label="Team size"
                  labelId="teamsize-select"
                  onChange={handleFormFieldChange}
                >
                  <MenuItem value="1-10">1-10</MenuItem>
                  <MenuItem value="10-50">10-50</MenuItem>
                  <MenuItem value="50-500">50-500</MenuItem>
                  <MenuItem value="500-1000">500-1000</MenuItem>
                  <MenuItem value="1000+">1000+</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <p style={{ color: "#757575" }}>
                Great! What kind of company is it?
              </p>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel htmlFor="company-type">Company type</InputLabel>
                <Select
                  labelId="company-type"
                  value={data.companyType}
                  label="Company type"
                  name="companyType"
                  onChange={handleFormFieldChange}
                >
                  <MenuItem value="Environmental research">
                    Environmental research
                  </MenuItem>
                  <MenuItem value="Medical devices">Medical devices</MenuItem>
                  <MenuItem value="Biologics, chemicals or drugs">
                    Biologics, chemicals or drugs
                  </MenuItem>
                  <MenuItem value="Academia/University research">
                    Academia/University research
                  </MenuItem>
                  <MenuItem value="Government research">
                    Government research
                  </MenuItem>
                  <MenuItem value="Other healthcare research or manufacturing">
                    Other healthcare research or manufacturing
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <p style={{ color: "#757575" }}>What is your role there?</p>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor="role-select">Your role</InputLabel>
                <Select
                  value={data.userRole}
                  labelId="role-select"
                  label="Your role"
                  name="userRole"
                  onChange={handleFormFieldChange}
                >
                  <MenuItem value="Lab Manager">Lab Manager</MenuItem>
                  <MenuItem value="Quality Assurace">Quality Assurace</MenuItem>
                  <MenuItem value="Researche scientist">
                    Researche scientist
                  </MenuItem>
                  <MenuItem value="Chemist">Chemist</MenuItem>
                  <MenuItem value="Regulatory affairs">
                    Regulatory affairs
                  </MenuItem>
                  <MenuItem value="Lab Technician">Lab Technician</MenuItem>
                  <MenuItem value="IT team">IT team</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Senior management">
                    Senior management
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <p style={{ color: "#757575" }}>
                Are you a manager? (Do people report to you?)
              </p>
              <FormControl fullWidth>
                <RadioGroup
                  aria-label="isManager"
                  name="isManager"
                  value={data.isManager}
                  onChange={handleFormFieldChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                disabled={
                  data.isManager === "" ||
                  !data.userRole ||
                  !data.teamSize ||
                  !data.companyType
                }
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={handleClick}
              >
                Continue to Company Name&nbsp;&nbsp;&nbsp;&nbsp;⟶
              </Button>
            </>
          )}
          {state.page === 3 && (
            <>
              <h1 style={{ fontFamily: "Roboto", fontWeight: "200" }}>
                What's your company called?
              </h1>
              <p style={{ color: "#757575" }}>Company name</p>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="company"
                value={data.company}
                helperText="We'll use this to name your LabLog team, you can always change it later"
                onChange={handleFormFieldChange}
              />
              <br />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                disabled={!data.company}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={handleClick}
              >
                Create Team&nbsp;&nbsp;&nbsp;&nbsp;⟶
              </Button>
            </>
          )}
          {state.page === 4 && (
            <>
              <h1 style={{ fontFamily: "Roboto", fontWeight: "200" }}>
                Add Colleagues
              </h1>
              <p style={{ color: "#757575" }}>
                Know a few colleagues who'd like to explore LabLog with you?
                <br />
                (This step is optional)
              </p>
              {data.teamUsers.map((user) => (
                <Box key={user.id} mb={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    id="user1"
                    fullWidth
                    value={user.email}
                    name={user.id}
                    placeholder="name@example.com"
                    onChange={handleInvitesChanged}
                  />
                </Box>
              ))}
              <Box mb={1.5} display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  onClick={() => {
                    setData((s) => ({
                      ...s,
                      teamUsers: [...s.teamUsers, { id: v4(), email: "" }],
                    }));
                  }}
                >
                  + Add more
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={handleClick}
              >
                Create My Account&nbsp;&nbsp;&nbsp;&nbsp;⟶
              </Button>
            </>
          )}
          {state.page === 5 && (
            <>
              <h1 style={{ fontFamily: "Roboto", fontWeight: "200" }}>
                Account Verification
              </h1>
              <p style={{ color: "#757575" }}>
                Thank you for your request. We need to manually verify your
                request to issue your account credentials. You will hear from us
                within 24 hours.
              </p>
              <Link to="/">Go back to LabLog</Link>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default CreateAccount;
